import React, { useState } from "react";

import { BiSearch } from "react-icons/bi";
import ArrowDown from "../../assets/arrow-down.jsx";
import "./index.css";

const sort = {
  date_asc: "Oldest to newest",
  date_desc: "Newest to oldest",
  name_asc: "A-Z Ascending order",
  name_desc: "Z-A Descending order",
};

const SearchSortFilter = ({
  onSearch,
  onSort,
  width,
  setSearch,
  selectedOption,
  marginLeft,
  ischat,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  return (
    <div
      className={
        !ischat
          ? "builder-input-chatbot justify-content-between"
          : "builder-input-chatbot "
      }
    >
      <div className="search-bar" style={{ width, marginLeft }}>
        <BiSearch className="icon-search" size="1.2rem" />
        <input
          type="text"
          placeholder="Search"
          name="search"
          id="sea"
          onChange={(e) => setSearch(e.target.value)}
          // onKeyDown={onSearch}
        />
      </div>
      <div className="node-filter-sort-div">
        {/* {!ischat ? (
          <>
            <div className="d-flex filter-bar">
              <label>Filter</label>
              <select name="" id="">
                <option value="" disabled selected>
                  Filter by name
                </option>
                <option value="">1</option>
                <option value="">2</option>
              </select>
            </div>
          </>
        ) : (
          ""
        )} */}
        <div className={ischat ? "d-flex sort-bar-chats" : "d-flex sort-bar"}>
          <label>Sort</label>
          <div className="custom-dropdowns" onClick={toggleDropdown}>
            <span className="selected-option">{sort[selectedOption]}</span>
            {/* <img src={DropDownArrow} alt="ArrowDown" /> */}

            <ArrowDown />
            <div className={`dropdowns-options ${dropdownOpen ? "open" : ""}`}>
              {Object.keys(sort).map((item) => (
                <div
                  key={item}
                  className={`dropdowns-option ${
                    item === selectedOption ? "dropdowns-option-act" : ""
                  }`}
                  onClick={() => onSort(item)}
                >
                  {sort[item]}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSortFilter;
