import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";

import { JsonEditor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { Tabs } from "react-bootstrap-tabs";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Trash from "../../../../../src/assets/trash-icon";
import Upload from "../../../../../src/assets/upload-icon";
import {
  createNode,
  getNode,
  imageUpload,
  updateNode,
} from "../../../../api/nodes";

import { nodesConstants, nodesConstantsEdit } from "../../../constants";
import { generateId, getAllStringValues, getInputs, removeSpaceFromObj } from "../../../utils";
import TabsComponent from "./tabs";

import { IMAGE_URL } from "../../../../api/common";
import "./index.css";

const excludedValues = ["jobId", "timestamp", "isoTimestamp", "guid"];
const method = ["POST", "GET", "PUT", "DELETE"];

const Keydata = ({ name, description, items, updateItems, className, value }) => {
  return (
    <div className={className}>
      <div className="parameter_head">
        <label className="labels">{name}</label>
      </div>
      <div className="parameter_desp">{description}</div>
      {(items || []).map((item, index) => {
        return (
          <div className="mr_all" key={index}>
            <div className="adding_parameters">
              <div className="space_btw1">
                <div className="desp_flex1">
                  <label className="labels">KEY</label>
                  <input
                    className="inputs"
                    placeholder="Select key"
                    value={value.name}
                    name={item.name}
                    onChange={(event) => {
                      updateItems(
                        items.map((i) => {
                          if (i.id === item.id) {
                            i.name = event.target.value;
                          }
                          return i;
                        })
                      );
                    }}
                  />
                </div>
                <div className="desp_flex2">
                  <label className="labels">Value</label>
                  <input
                    className="inputs"
                    placeholder="Enter the value"
                    value={value.name}
                    name={item.name}
                    onChange={(event) => {
                      updateItems(
                        items.map((i) => {
                          if (i.id === item.id) {
                            i.value = event.target.value;
                          }
                          return i;
                        })
                      );
                    }}
                  />
                </div>
                <div
                  className="del_btn"
                  onClick={() => {
                    updateItems(items.filter((i) => i.id !== item.id));
                  }}
                >
                  <Trash />
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <div className="mr_all">
        <div
          id="dropzone1"
          onClick={() => {
            updateItems([...items, { id: generateId(8), name: "", value: "" }]);
          }}
        >
          <label>
            <p className="caption1">+ Add new parameter</p>
          </label>
        </div>
      </div>
    </div>
  );
};

const ApiMethod = ({
  methodValue,
  urlValue,
  handleChange,
  errorMethod,
  errorUrl,
}) => {
  return (
    <div className="API_method_box">
      <div className="mr_all">
        <div className="auth-inputs">
          <div className="desp_flex">
            <label className="labels">API method</label>
            <select name="method" value={methodValue} onChange={handleChange}>
              <option>Select API method</option>
              {method.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
              {errorMethod ? (
                <p className="formik_error">{errorMethod}</p>
              ) : null}
            </select>
          </div>
          <div className="desp_flex">
            <label className="labels">URL</label>
            <input
              className="inputs"
              placeholder="ex:https://www.example.com"
              name="url"
              value={urlValue}
              onChange={handleChange}
            />
            {errorUrl ? <p className="formik_error">{errorUrl}</p> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const RequestBodyBox = ({ name, value, onChange }) => {
  const ref = useRef();
  useEffect(() => {
    ref.current.jsonEditor.set(value);
  }, [value]);

  return (
    <div className="request_body_box">
      <div className="parameter_head">
        <label className="labels">Request body</label>
      </div>
      <div className="parameter_desp">
        Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam sapien
        scelerisque ac.
      </div>
      <div className="json-editor">
        <JsonEditor
          ref={ref}
          name={name}
          mode="text"
          value={removeSpaceFromObj(value) || {}}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const StyledInput = ({ lable, handleChange, value, error, name }) => (
  <div className="left-side">
    <div className="space_btw">
      <div className="leftside-input">
        <label className="labels"> {lable}</label>
        <input
          type="text"
          className="inputs"
          placeholder="Enter node name"
          value={value}
          name={name}
          onChange={handleChange}
        />
        {error ? <p className="formik_error">{error}</p> : null}
      </div>
    </div>
  </div>
);

const HeadDes = ({ head, des }) => (
  <div>
    <div className="header_text">{head} </div>
    <p className="description-auth">{des}</p>
  </div>
);

const ImageUpload = ({ onChange }) => (
  <input
    type="file"
    name="logo"
    placeholder="Enter Node Name"
    onChange={onChange}
    value={undefined}
    hidden
    accept="image/*"
    alt="select-file"
  />
);

const StatusCode = ({ name,item, i, data, updateData, value }) => {
  const ref = useRef();
  useEffect(() => {
    ref.current.jsonEditor.set(value.variables);
  }, [value.variables]);

  return (
    <div key={i}>
      <div className="status-trash-div">
        <div className="desp_flex3">
          <label className="labels-status">Status code</label>
          <input
            className="inputs"
            placeholder="Enter the code"
            value={removeSpaceFromObj(value.status)}
            name={name}
            onChange={(event) => {
              updateData(
                data.map((i) => {
                  if (i.id === item.id) {
                    i.status = event.target.value;
                  }
                  return i;
                })
              );
            }}
          />
        </div>
        <div className="del-btn-main-div">
          <div
            className="del_btn1"
            onClick={() => {
              updateData(data.filter((i) => i.id !== item.id));
            }}
          >
            <Trash />
          </div>
        </div>
      </div>
      <div className="json-editor">
        <div>{item.variables?.value}</div>
        <JsonEditor
          ref={ref}
          mode="text"
          name={item.variables}
          value={value?.variables}
          onChange={(value) => {
            updateData(
              data.map((i) => {
                if (i.id === item.id) {
                  i.variables = value;
                }
                return i;
              })
            );
          }}
        />
      </div>
    </div>
  );
};

const Statuscodes = ({ name,value, description, className, data, updateData }) => {
  return (
    <div className={className}>
      <div className="parameter_head">
        <label className="labels-status">{name}</label>
      </div>
      <div className="parameter_desp">{description}</div>
      {Array.isArray(data) &&
        data.map((item, i) => (
          <StatusCode
            item={item}
            value={value}
            key={i}
            data={data}
            name={name}
            i={i}
            updateData={updateData}
          />
        ))}
        
      <div className="mr_all">
        <div
          id="dropzone2"
          onClick={() => {
            updateData([
              ...data,
              { id: generateId(6), status: "", variables: {} },
            ]);
          }}
        >
          <label>
            <p className="caption1">+ Add new parameter</p>
          </label>
        </div>
      </div>
    </div>
  );
};

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Only alphabets are allowed")
    .required("Node name required"),
  category: Yup.string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Only alphabets are allowed")
    .required("Category required"),
  sub_category: Yup.string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Only alphabets are allowed")
    .required(" SubCategory required"),
});

const Details = ({ nodeId, isEdit }) => {
  const [saving, setSaving] = useState(false);
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: isEdit ? nodesConstantsEdit : nodesConstants,
    enableReinitialize:true,
    
    validationSchema: validationSchema,
    onSubmit:async (values, onSubmitForm) => {
      setDisable(true)
      try {
        values.inputs = inputs;
        values.outputs = outputs;
        values.logo = logoBase64;
        setSaving(true);
        if (isEdit && isEdit === true) {
          updateNode(values)
            .then((res) => {
              if (res.id) {
                onSubmitForm.resetForm();
                toast.success("Successfully Updated " + values.name);
                setSaving(false);
                setDisable(false)
                setTimeout(() => {
                  navigate(-1);
                }, 0);
              }
              setSaving(false);
            })
            .catch((err) => {
              toast.error("unable to update node with ", err.message);
              setSaving(false);
              setDisable(false);
            });
          return;
        }
        createNode(values)
          .then((res) => {
            if (res.name) {
              toast.success("Successfully Created " + values.name);
              onSubmitForm.resetForm();
              setSaving(false);
              setDisable(false)
              setTimeout(() => {
                navigate(-1);
              }, 0);
            }
          })
          .catch((err) => {
            toast.error("Unable to create node with ", err.message);
            setSaving(false);
            setDisable(false)
          });
      } catch (err) {
        toast.error("error in node ", err.message);
        setDisable(false)
      }
    },
  });
  const [logoBase64, setLogoBase64] = useState(formik.values.logo);


  const flattenObj = (ob) => {
 
    // The object which contains the
    // final result
    let result = {};
 
    // loop through the object "ob"
    for (const i in ob) {
 
        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
            const temp = flattenObj(ob[i]);
            for (const j in temp) {
 
                // Store temp in result
                result[i + '.' + j] = temp[j];
            }
        }
 
        // Else store ob[i] in result directly
        else {
            result[i] = ob[i];
        }
		
    }
    return result;
};


  let inputs = useMemo(() => {
    let values = JSON.parse(JSON.stringify(formik.values));
    values.auth.status_codes = [];
    values.status_codes = [];
    let res = getInputs(flattenObj(values))
    return res;
  }, [formik.values]);

  const [outputs, internal] = useMemo(() => {
    const newValue = JSON.parse(JSON.stringify(formik.values));

    let internalValues = {};

    if (formik.values.auth?.status_codes) { 
      internalValues = (newValue.auth?.status_codes || []).map(
        (item) => item.variables
      );
      newValue.auth.status_codes = undefined;
    }

    return [
      (formik.values.status_codes || []).map((status) => {
        const opts = getAllStringValues(status.variables);
        return { id: status.id, status: status.status, data: opts };
      }),
      getAllStringValues(internalValues),
    ];
  }, [formik.values]);

  // inputs = inputs.filter((input) => !internal.find((i) => i === input));
  // inputs = inputs.filter((input) => !excludedValues.find((ex) => ex === input));



  const updateAuth = (field, obj) =>{
      removeSpaceFromObj(obj);
    formik.setFieldValue("auth", {
      ...formik.values.auth,
      [field]: obj,
    });

  }

  const onChangeImage = (event) => {
    const file = event.target.files[0];
    imageUpload(file)
      .then((res) => {
        setLogoBase64(res.id);
      })
      .catch((err) => {
        toast.error("unable to fetch id", err);
      });

    // const reader = new FileReader();

    // reader.onloadend = (e) => {
    //   setLogoBase64(reader.result);
    // };

    // if (file) {
    //   reader.readAsDataURL(file);
    // }
  };

  const onChangeAuth = (e) => {
    const { value, name } = e.target;
    updateAuth(name, value);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    imageUpload(file)
      .then((res) => {
        setLogoBase64(res.id);
      })
      .catch((err) => {
        toast.error("unable to fetch id", err);
      });

    // if (file && file.type.match("image.*")) {
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     setLogoBase64(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // }
    }

  const imageCheck = (logo) => {
    return logo?.length < 30;
  };

  useEffect(() => {
    if (isEdit === true && nodeId) {
      getNode(nodeId)
        .then((detail) => {
          formik.setValues(detail);
          setLogoBase64(detail.logo);
        })
        .catch((err) => {
          toast.error("unable to get node with ", nodeId);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, nodeId]);

  return (
    <>
      <div className="pos_fixed">
        <Breadcrumb>
          <Breadcrumb.Item href="#" className="breadCrumb-group">
            <NavLink to="/home/nodes">Nodes</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active href="#">
            {isEdit && isEdit === true ? "Edit Nodes" : "Create Nodes"}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="node-builder-heading">
          <div className="node-builder-main-heading">
            <h1>Node Builder</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam
              sapien scelerisque ac.
            </p>
          </div>
        </div>
        <div className="node-builder-heading pt-4">
          <div className="node-builder-main-heading">
            <h1>{isEdit && isEdit === true ? "Edit Node" : "Create node"}</h1>
          </div>
          <button
            className=""
            style={{
              position: "absolute", 
              right: "50px"
            }}
            type="submit"
            onClick={formik.handleSubmit}
            disabled={!formik.isValid || saving}
          >
            {saving === true ? (
              "Saving ..."
            ) : (
              <>
                {isEdit && isEdit === true ? "Update Changes" : "Save Changes"}
              </>
            )}
          </button>
        </div>
      </div>

      {/* body of create node  */}
      <div className="wid-100">
        <div className="nodeDetail_main">
          <div className="left-side-component">
            <div className="header_text1">NodeDetails </div>
            <div>
              <div className="first_box">
                <div className="wid-10">
                  <div className="w-50 mr-6">
                    <StyledInput
                      lable={"Node Name"}
                      handleChange={formik.handleChange}
                      value={formik.values.name}
                      error={formik.errors.name}
                      name={"name"}
                    />
                    <StyledInput
                      lable={"Sub Category"}
                      handleChange={formik.handleChange}
                      value={formik.values.sub_category}
                      error={formik.errors.sub_category}
                      name={"sub_category"}
                    />
                    <StyledInput
                      lable={"Category"}
                      handleChange={formik.handleChange}
                      value={formik.values.category}
                      error={formik.errors.category}
                      name={"category"}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="column">
                      <label className="labels">Icon</label>
                    </div>
                    <div
                      className="mr_all"
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDrop={handleDrop}
                    >
                      <div id={logoBase64 === "" ? "dropzone" : "dropzone_pd"}>
                        <label className="label-dropzone">
                          {logoBase64 !== "" && (
                            <img
                              src={
                                imageCheck(logoBase64)
                                  ? IMAGE_URL + logoBase64
                                  : `data:image/png;base64 ,${logoBase64}`
                              }
                              style={{ border: "none" }}
                              width="120"
                              height="120"
                              className="logo-img"
                              alt="logo"
                            />
                          )}
                          <ImageUpload onChange={onChangeImage} />
                          {logoBase64 === "" ? (
                            <div className="btn-dropzone">
                              <div>
                                <p className="caption">
                                  Upload or drag and drop you logo
                                </p>
                                <p className="caption"> here to upload</p>
                              </div>
                              <div className="upload_div">
                                <label className="upload_btn">
                                  <ImageUpload onChange={onChangeImage} />
                                  <Upload />
                                  Upload logo
                                </label>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mr_all">
                  <div className="wi-99">
                    <label className="labels">Description</label>
                    <textarea
                      className="description w-100"
                      placeholder="Enter description"
                      value={formik.values.description}
                      name="description"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <HeadDes
              head={"Authentication"}
              des={
                "Build a form with fields for each item your API requires for authentication, such as username, password, subdomain, and more."
              }
            />
            <div className="second_box">
              <div className="mr_all">
                <div className="space_btw">
                  <div className="desp_flex">
                    <label className="labels">Authentication type</label>
                    <select
                      value={formik.values.auth?.type}
                      onChange={(e) =>
                        formik.setFieldValue("auth", {
                          ...formik.values.auth,
                          type: e.target.value,
                        })
                      }
                      name="method"
                    >
                      <option value={""}>Select Authentication Type</option>
                      <option value={"Basic"}>Basic Auth</option>
                      <option value={"Session"}>Session Auth</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {formik.values.auth?.type === "Basic" ? (
              <div className="second_box">
                <div className="mr_all">
                  <div className="auth-inputs m-l-0">
                    <div className="desp_flex">
                      <label className="labels">Username</label>
                      <input
                        className="inputs"
                        placeholder="Enter name"
                        name="username"
                        value={formik.values.auth?.username}
                        onChange={(e) => updateAuth("username", e.target.value)}
                      />
                    </div>
                    <div className="desp_flex">
                      <label className="labels" id="authentication">
                        Password
                      </label>
                      <input
                        className="inputs"
                        placeholder="Enter Password"
                        type="password"
                        name="password"
                        value={formik.values.auth?.password}
                        onChange={(e) => updateAuth("password", e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {formik.values.auth?.type === "Session" ? (
              <>
                <ApiMethod
                  methodValue={formik.values.auth?.method}
                  urlValue={formik.values.auth?.url}
                  handleChange={onChangeAuth}
                />

                <Keydata
                  className="Parameter_box"
                  description={`Use the session authentication type if
                  you need to collect some information
                  from your users, for example a user name
                  and password, and then make a request to
                  your API to exchange that information
                  for a token or session key, which you
                  will use to authorize subsequent API
                  requests`}
                  name="params"
                  value={formik.values.auth?.params}
                  items={formik.values.auth?.params || []}
                  updateItems={(items) => updateAuth("params", items)}
                />
                <Keydata
                  className="headers_box"
                  description={`Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam
                sapien scelerisque ac.`}
                  name="headers"
                  value={formik.values.auth?.headers}
                  items={formik.values.auth?.headers || []}
                  updateItems={(items) => updateAuth("headers", items)}
                />
                <RequestBodyBox
                name="body"
                  value={removeSpaceFromObj(formik.values.auth?.body)}
                  onChange={(value) => updateAuth("body", value)}
                />
                <Statuscodes
                  className="status_box"
                  description={`Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam
                sapien status new ac.`}
                  name={"auth.status_codes"}
                  data={formik.values.auth?.status_codes || {}}
                  value={formik.values.auth?.status_codes}
                  updateData={(data) =>{
                    removeSpaceFromObj(data[0]?.variables);
                    formik.setFieldValue("auth.status_codes", data)
                  }}
                />
              </>
            ) : (
              ""
            )}
            <HeadDes
              head={"API Request configuration"}
              des={
                " Enter the URL where Node will make the request had send the input form data. The Create request must return an object."
              }
            />
            <ApiMethod
              methodValue={formik.values.method}
              urlValue={formik.values.url}
              handleChange={formik.handleChange}
              errorMethod={formik.errors.method}
              errorUrl={formik.errors.url}
              name="url"
            />

            <Keydata
              className="Parameter_box"
              name={"params"}
              description={`param`}
              value={formik.values.params || []}
              items={formik.values.params || []}
              updateItems={(items) => formik.setFieldValue("params", items)}
            />

            <Keydata
              className="headers_box"
              description={`header`}
              name={"headers"}
              value={formik.values.headers || []}
              items={formik.values.headers || []}
              updateItems={(items) => formik.setFieldValue("headers", items)}
            />

            <RequestBodyBox
              value={formik.values?.body}
              name={"body"}
              onChange={(value) => {
                removeSpaceFromObj(value);
                formik.setFieldValue("body", value)
              }}
            />

            <Statuscodes
              className="status_box"
              description={`status code`}
              name={"status_codes"}
              data={formik.values?.status_codes || {}}
              value={formik.values?.status_codes}
              updateData={(data) => {
                removeSpaceFromObj(data[0]?.variables);
                formik.setFieldValue("status_codes", data)
              }}
            />
          </div>
        </div>

        <div className="tab-componet">
          <div className="main-tab-div">
            <div className="inner-tab-div">
              <div>
                <label className="top-content"> Your Responses</label>
              </div>
              <Tabs>
                <TabsComponent label={"Inputs"} array={inputs} />
                <TabsComponent label={"Outputs"} array={outputs} />
                <TabsComponent label={"Internals"} array={internal} />
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;