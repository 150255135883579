import React from "react";

export default function UploadIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94964 13.8558C8.63854 13.856 8.3387 13.7395 8.10929 13.5294C7.87988 13.3192 7.73758 13.0308 7.71047 12.7208C7.59283 11.3784 7.56304 10.0297 7.6213 8.68334C7.55101 8.67859 7.48073 8.67359 7.41047 8.66834L6.1688 8.57834C5.98747 8.56509 5.81268 8.50499 5.66155 8.4039C5.51042 8.30282 5.38813 8.16422 5.30665 8.00168C5.22517 7.83914 5.18729 7.65823 5.19673 7.47665C5.20616 7.29508 5.26258 7.11906 5.36047 6.96584C6.27464 5.53584 7.9488 3.93584 9.32714 2.94584C9.52375 2.80469 9.75969 2.72876 10.0017 2.72876C10.2438 2.72876 10.4797 2.80469 10.6763 2.94584C12.0538 3.93584 13.728 5.53584 14.6413 6.96584C14.7392 7.11906 14.7956 7.29508 14.805 7.47665C14.8145 7.65823 14.7766 7.83914 14.6951 8.00168C14.6136 8.16422 14.4914 8.30282 14.3402 8.4039C14.1891 8.50499 14.0143 8.56509 13.833 8.57834L12.5913 8.66834L12.3813 8.68334C12.4396 10.03 12.4096 11.3783 12.2921 12.7208C12.265 13.0308 12.1227 13.3192 11.8933 13.5294C11.6639 13.7395 11.3641 13.856 11.053 13.8558H8.94964ZM8.90297 8.13501C8.80806 9.62449 8.82561 11.119 8.95547 12.6058H11.0471C11.1771 11.1192 11.1946 9.62501 11.1005 8.13501C11.0953 8.05244 11.1066 7.96965 11.1336 7.89147C11.1607 7.81328 11.203 7.74125 11.2581 7.67954C11.3132 7.61784 11.3801 7.56769 11.4547 7.53201C11.5293 7.49632 11.6103 7.47582 11.693 7.47168C11.963 7.45834 12.2321 7.44168 12.5013 7.42168L13.4021 7.35668C12.6021 6.17805 11.6007 5.14955 10.4438 4.31834L10.0013 4.00084L9.5588 4.31834C8.40192 5.14954 7.40051 6.17804 6.60047 7.35668L7.5013 7.42251C7.77047 7.44168 8.04047 7.45834 8.30964 7.47168C8.39227 7.47582 8.47326 7.49632 8.54791 7.53201C8.62255 7.56769 8.68937 7.61784 8.74449 7.67954C8.79961 7.74125 8.84192 7.81328 8.86898 7.89147C8.89605 7.96965 8.90732 8.05244 8.90214 8.13501H8.90297Z"
        fill="white"
      />
      <path
        d="M4.79297 14.1667C4.79297 14.0009 4.72712 13.8419 4.60991 13.7247C4.4927 13.6075 4.33373 13.5417 4.16797 13.5417C4.00221 13.5417 3.84324 13.6075 3.72603 13.7247C3.60882 13.8419 3.54297 14.0009 3.54297 14.1667V15.8333C3.54297 16.6383 4.1963 17.2917 5.0013 17.2917H15.0013C15.3881 17.2917 15.759 17.138 16.0325 16.8645C16.306 16.591 16.4596 16.2201 16.4596 15.8333V14.1667C16.4596 14.0009 16.3938 13.8419 16.2766 13.7247C16.1594 13.6075 16.0004 13.5417 15.8346 13.5417C15.6689 13.5417 15.5099 13.6075 15.3927 13.7247C15.2755 13.8419 15.2096 14.0009 15.2096 14.1667V15.8333C15.2096 15.8886 15.1877 15.9416 15.1486 15.9806C15.1095 16.0197 15.0566 16.0417 15.0013 16.0417H5.0013C4.94605 16.0417 4.89306 16.0197 4.85399 15.9806C4.81492 15.9416 4.79297 15.8886 4.79297 15.8333V14.1667Z"
        fill="white"
      />
    </svg>
  );
}
